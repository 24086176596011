import { useEffect} from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import {InlineWidget} from 'react-calendly'



import home_finding_title from '../Assets/Images/moving_to_france_home_7.jpg';

import avatar_male from '../Assets/Images/avatar_male.svg';
import avatar_female from '../Assets/Images/avatar_female.svg';


const Content = () => {
    useEffect(() => {
        document.title = "Home Finding Services";
    }, []);
   
    return (
        <>
        <Container className='bg-white g-0'>
            <div class="p-4 p-md-5 rounded text-body-emphasis background-blend " style={{ backgroundImage: `url("${home_finding_title}")` }}>
                <div class="col-lg-6 px-3 text-blend">
                    <h1 class=" ">Discovery Call</h1>
                    <p class="lead my-3">Book a FREE 30 minutes phone call with either Emma or Sean.</p>
                </div>
            </div>
        </Container>

        <Container className=" bg-white "  >
            <Row>
                
                <Col xs={12} md={12} className="d-flex flex-column   p-3 py-5">
                    <h2>Discovery Call</h2>
                </Col>
            </Row>
            <Row>
            <Col md={{span:4,  offset:1}}>

<Image src={ avatar_female } fluid />
<InlineWidget 
    url="https://calendly.com/lanebiarritz" 
    pageSettings={{
        backgroundColor: 'ffffff',
        hideEventTypeDetails: false,
        hideLandingPageDetails: false,
        primaryColor: '00a2ff',
        textColor: '4d5055'
      }}    
/>
</Col>
                <Col md={{span:4,  offset:1}}>
                    <Image src={ avatar_male } fluid />
                    <InlineWidget 
                        url="https://calendly.com/seanmtfuk/discovery-call" 
                        pageSettings={{
                            backgroundColor: 'ffffff',
                            hideEventTypeDetails: false,
                            hideLandingPageDetails: false,
                            primaryColor: '00a2ff',
                            textColor: '4d5055'
                          }}    
                    />
                 </Col>

            </Row>
        </Container>
        </>
    );
}

export default Content;