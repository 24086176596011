
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Row from 'react-bootstrap/Row';
import { useLocation } from 'react-router-dom';

import logo from '../Assets/Images/move_to_france.svg';


const NavBar = () => {
  const location = useLocation();


  return(
    <>

    <div className='top-bar '>
      <Container className='border-bottom lh-1 py-3'>
        <Row className='flex-nowrap justify-content-between '>
            <div class=" col-md-4 pt-1 no_mob">
              <i className='fa fa-envelope '></i>&nbsp;&nbsp;hello@movetofrance.uk
            </div>
          <div class="col-xs-6 col-sm-6 col-md-4 ">
            <Navbar.Brand href="/">
                <img alt="Move to France" src={ logo } className="d-inline-block align-top logo" />
                <a class="header-logo-text text-decoration-none" href="/">Move to France</a>
            </Navbar.Brand>
          </div>
              <span className='mob_only'><a href="mailto:hello@movetofrance.uk" ><i className='fa fa-envelope '></i></a></span>
          <div class="col-xs-6 col-sm-6 col-md-4  ">
              
              {/* <i className='fa fa-brands fa-instagram'></i>
              <i className='fa fa-brands fa-facebook'></i>
              <i className='fa fa-brands fa-linked'></i>
              <i className='fa fa-brands fa-x'></i> */}
              
              <span className='mob_only'><a href="mailto:hello@movetofrance.uk" ><i className='fa fa-envelope '></i></a></span>
          </div>
         
        </Row>
      </Container>
    </div>



    <Container className=' mb-0 bg-white border-bottom'>
      <Navbar expand="lg" className=" navbar-outer p-0 nav-scroller nav nav-underline justify-content-between " id="navbar">
        <Container className="navbar-inner">
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Container className="h-100"  >
            
              <Navbar.Collapse id="basic-navbar-nav" >
                
             
                <Nav   activeKey={location.pathname} className='me-auto'>
                  <Nav.Link className='nav-item nav-link link-body-emphasis' href="/" >Home</Nav.Link>
                  <Nav.Link className='nav-item nav-link link-body-emphasis' href="/considerations" >Considerations</Nav.Link>
                  <Nav.Link className='nav-item nav-link link-body-emphasis' href="/home-finding-services" >Home Finding Services</Nav.Link>
                  <Nav.Link className='nav-item nav-link link-body-emphasis' href="/relocation-services" >Relocation Services</Nav.Link>

                  <Nav.Link className='nav-item nav-link link-body-emphasis' href="/settling-services" >Settling Services</Nav.Link>
                  <Nav.Link href="/contact" >Contact</Nav.Link>
      
                  </Nav>
                  <Nav activeKey={location.pathname} className='' >
                   <Nav.Link className='nav-item nav-link link-body-emphasis' href="/book-discover-call" >Free Discovery Call</Nav.Link>

                  </Nav>
              </Navbar.Collapse>
              

          </Container>
        </Container>
      </Navbar>
    </Container>
    </>
  );
}


export default NavBar;