import { useEffect} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button'; 
import home_finding_title from '../../Assets/Images/moving_to_france_home_10.jpg';

const Content = () => {
    useEffect(() => {
        document.title = "Contact us for more information";
    }, []);
   
    return (
        <>
        <Container className='bg-white g-0'>
            <div class="p-4 p-md-5 rounded text-body-emphasis background-blend " style={{ backgroundImage: `url("${home_finding_title}")` }}>
                <div class="col-lg-6 px-3 text-blend">
                    <h1 class=" ">Contact Us</h1>
                    <p class="lead my-3">We’re here to help! Reach out with any questions or feedback, and we'll get back to you as soon as possible.</p>
                </div>
            </div>
        </Container>
            
        <Container className=" bg-white "  >
            <Row>
                
                <Col xs={12} md={6} className="d-flex flex-column p-3 py-5">
                    <strong>Complete the Form Below and we will contact you as soon as possible</strong>
                    <div className=" w-100 m-auto">
            <form>

                    <div className="">
                        <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com" />
                        <label for="floatingInput">Email Address</label>
                    </div>
                    
                    <div className="">
                        <input type="password" className="form-control" id="floatingPassword" placeholder="Password" ></input>
                        <label for="floatingPassword">Name</label>
                    </div>

                    <Button className="w-100 py-2" href="/profile">Sign in</Button>
                    <p className="mt-5 mb-3 text-body-secondary">© 2024</p>
            </form>
        </div>


                </Col>
            </Row>
        </Container>
        </>
    );
}

export default Content;