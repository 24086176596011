import { useEffect} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Button from 'react-bootstrap/Button';



// import { Image, Container } from 'react-bootstrap';
// import { LoremIpsum } from "react-lorem-ipsum";

import './Profile.css';

const Content = () => {

 useEffect(() => {
     document.title = "Profile";
   }, []);

  return (
    <>
    <Row className="page-title-box" >
        <center>
            <h1 className="page-title">Profile Settings</h1>
        </center>
    </Row>
    <Container className="rounded bg-white mt-5 mb-5 starter"  >
    <Row>
        <Col xs={12} md={4} className="border-right">
            <div class="d-flex flex-column align-items-center text-center p-3 py-5">
                <img class="rounded-circle mt-5" width="150px" src="https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg" />
                <span class="font-weight-bold">Edogaru</span><span class="text-black-50">edogaru@mail.com.my</span><span> </span>
                <hr />
                <Button className="w-100 my-2" variant="outline-success">Download Town Form</Button>
                <Button className="w-100 my-2" variant="outline-success">Download Insurance Documents</Button>
                <hr />
                <Button className="w-100 my-2" variant="outline-primary">Upload</Button>

            </div>
        </Col>
        <Col md={4} xs={12} className="border-right">
            <div class="p-3 py-5">
                <div class="d-flex justify-content-between align-items-center mb-3">
                    
                </div>
                <Row className=" mt-2">
                    <Col md={6}><label class="labels">Name</label><input type="text" class="form-control" placeholder="first name" value="" /></Col>
                    <Col md={6}><label class="labels">Surname</label><input type="text" class="form-control" value="" placeholder="surname" /></Col>
                </Row>
                <Row className=" mt-3">
                    <div class="col-md-12"><label class="labels">Mobile Number</label><input type="text" class="form-control" placeholder="enter phone number" value="" /></div>
                    <div class="col-md-12"><label class="labels">Address Line 1</label><input type="text" class="form-control" placeholder="enter address line 1" value="" /></div>
                    <div class="col-md-12"><label class="labels">Address Line 2</label><input type="text" class="form-control" placeholder="enter address line 2" value="" /></div>
                    <div class="col-md-12"><label class="labels">Postcode</label><input type="text" class="form-control" placeholder="enter address line 2" value="" /></div>
                    <div class="col-md-12"><label class="labels">Town/City</label><input type="text" class="form-control" placeholder="enter address line 2" value="" /></div>
                    <div class="col-md-12"><label class="labels">County</label><input type="text" class="form-control" placeholder="enter address line 2" value="" /></div>
                    <div class="col-md-12"><label class="labels">Email</label><input type="text" class="form-control" placeholder="enter email id" value="" /></div>
                    <Col md={12}><label class="labels">Country</label><input type="text" class="form-control" placeholder="country" value="" /></Col>
                </Row>
                
                <Button className="w-100 py-2">Save Profile</Button>
            </div>
        </Col>
        <Col md={4}>
            <div class="p-3 py-4">
                <div class="">
                    <h4 class="text-right">Completion Status 60%</h4>
                    <ProgressBar now={60} />
                </div>
                
                <div class="col-md-12">
                    <Form>
                        <div key={'default-checkbox'} className="mb-3">
                            <Form.Check type={'checkbox'}
                                disabled
                                id={'default-checkbox'}
                                label={'Profile Completed'}
                                checked
                                
                            />

                            <Form.Check
                                disabled
                                
                                type={'checkbox'}
                                label={'Identification Uploaded'}
                                checked
                            />
            
                            <Form.Check
                                disabled
                                isValid
                                type={'checkbox'}
                                label={'School Acceptance'}
                            />                            
                            
                            <Form.Check
                                disabled
                                isValid
                                type={'checkbox'}
                                label={'Certificate Approved'}
                            /> 

                        </div>
                    </Form>
                
                </div>

                
                
            </div>
        </Col>
    </Row>





    </Container>
    </>
  )
};

export default Content;
