import { useEffect} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import home_finding_title from '../../Assets/Images/moving_to_france_home_7.jpg';

const Content = () => {
    useEffect(() => {
        document.title = "Home Finding Services";
    }, []);
   
    return (
        <>
        <Container className=' bg-white g-0'>
            <div class="p-4 p-md-5 rounded text-body-emphasis background-blend " style={{ backgroundImage: `url("${home_finding_title}")` }}>
                <div class="col-lg-6 px-3 text-blend">
                    <h1 class=" ">Home Finding Services</h1>
                    <p class="lead my-3">Finding the perfect home can be a daunting task, but with our dedicated home finding service, it doesn't have to be. We specialize in making your home search as smooth and stress-free as possible, helping you discover the home of your dreams with ease and confidence.</p>
                    <p class="lead mb-0"><a href="/contact" class="text-body-emphasis fw-bold">Contact Us</a></p>
                </div>
            </div>
        </Container>
            
        <Container className="bg-white "  >
            <Row>
                
                <Col xs={12} md={12} className="d-flex flex-column   p-3 py-5">
                    
                        
                        <h2>Why Choose Our Home Finding Service?</h2>
                        <strong>Personalized Search</strong>
                        <p>We understand that every homebuyer has unique preferences and needs. Our experienced agents take the time to get to know you, your lifestyle, and your priorities. Whether you’re looking for a cozy apartment, a spacious family home, or a luxurious estate, we tailor our search to match your specific criteria.</p>

<strong>Extensive Listings</strong>
<p>Our comprehensive database includes a wide range of properties, from new constructions to charming historical homes, and everything in between. With access to the latest listings and off-market opportunities, we ensure you have the most up-to-date information at your fingertips.</p>

<strong>Expert Guidance</strong>
<p>Navigating the real estate market can be overwhelming, but our knowledgeable agents are here to guide you every step of the way. From initial consultations to final negotiations, we provide expert advice and support, ensuring you make informed decisions throughout the process.</p>

<strong>Advanced Search Tools</strong>
<p>Utilize our advanced search tools to filter properties by location, price range, size, amenities, and more. Our intuitive platform makes it easy to narrow down your options and find homes that meet your exact specifications.</p>

<strong>Neighbourhood Insights</strong>
<p>Choosing a home is not just about the property itself, but also about the community it’s located in. Our agents provide valuable insights into neighbourhoods, including school districts, local amenities, transportation options, and community vibes, helping you find a place that feels like home.</p>

<strong>Hassle-Free Experience</strong>
<p>We handle all the details, from scheduling viewings to managing paperwork, so you can focus on what matters most – finding your new home. Our goal is to make the home buying process as seamless and enjoyable as possible.</p>

<h4>How It Works</h4>
<strong>1. Initial Consultation</strong>
<p>Meet with one of our friendly agents to discuss your needs, preferences, and budget.</p>

<strong>2. Customized Property List</strong>
<p>Receive a curated list of properties that match your criteria, complete with detailed descriptions and high-quality images.</p>
<strong>3. Viewings and Tours</strong>
<p>Schedule convenient times to tour your favorite properties, either in-person or virtually.</p>
<strong>4. Expert Negotiation</strong>
<p>Our agents will negotiate on your behalf to ensure you get the best possible deal.</p>
<strong>5. Closing the Deal</strong>
<p>We assist with all the final paperwork and logistics, ensuring a smooth closing process.</p>

<h2>Client Testimonials</h2>
<p><i>"The team at Your Dream Home Finder made the search for our new home a wonderful experience. They listened to our needs and found us the perfect house in a fantastic neighbourhood. Highly recommend!" - Sarah & John D.</i></p>

"I was amazed by the level of service and expertise provided. They made everything so easy and stress-free. Thank you!" - Emily R.

Contact Us
Ready to start your home search? Contact us today to schedule your initial consultation and take the first step toward finding your dream home.

{/* Phone: (+44) 7878787 */}
Email: hello@movetofrance.com


Discover your dream home with us – where personalized service meets exceptional results.
                        <li>Rigorous selection of properties based on your requirements</li>
                        <li>Organization of a day of property visits</li>
                        <li>Transportation of the whole family to each property</li>
                        <li>Professional assistance and advice during each visit</li>
                        <li>Preparation of a complete housing dossier</li>
                        <li>Negotiations of the lease conditions</li>
                        <li>Complete lease application and submission</li>
                        <li>Confirmation and detailed explanation of the terms of the lease</li>
                        <li>Assistance during the signature of the lease</li>
                    
                </Col>
            </Row>
        </Container>            
        </>
    );
}

export default Content;