import { useEffect} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import home_finding_title from '../../Assets/Images/moving_to_france_home_9.jpg';

const Content = () => {
    useEffect(() => {
        document.title = "Settling Services";
    }, []);
   
    return (
        <>
        <Container className='g-0 bg-white'>
            <div class="p-4 p-md-5 rounded text-body-emphasis background-blend " style={{ backgroundImage: `url("${home_finding_title}")` }}>
                <div class="col-lg-6 px-3 text-blend">
                    <h1 class=" ">Settling Services</h1>
                    <p class="lead my-3">Our amazing settling services provide personalized support to ensure a smooth transition to your new country, helping you navigate everything from housing to local customs. With our dedicated team by your side, you'll quickly feel at home and ready to embrace your new adventure.</p>
                    <p class="lead mb-0"><a href="/contact" class="text-body-emphasis fw-bold">Contact Us</a></p>
                </div>
            </div>
        </Container>
            
        <Container className=" bg-white "  >
            <Row>
                
                <Col xs={12} md={12} className="d-flex flex-column   p-3 ">
                    <p>Our settling-in services are designed to make your transition to a new country as smooth and stress-free as possible. We understand that adapting to a new environment can be challenging, which is why we offer comprehensive support tailored to your unique needs. From help with finding accommodation and setting up utilities to guidance on navigating local customs and regulations, our dedicated team is here to assist you every step of the way. We also provide resources for understanding healthcare systems, educational options, and employment opportunities, ensuring that you feel at home in your new surroundings.</p>
                    <p>In addition to practical assistance, our settling-in services focus on fostering a sense of community and belonging. We organize social events, cultural workshops, and networking opportunities to help you connect with fellow expatriates and locals alike. These initiatives not only enhance your integration experience but also enrich your understanding of the local culture. With our support, you can embrace your new life with confidence and enthusiasm, making the most of your adventure in a new country.</p>
                    <strong>Our Settling Services</strong>
                    <p>We offer three types of services, each with its own levels of benefits</p>

            <h3>General Settling-In Services</h3>
            <ul>
                <li>Organization of the home entry inspection</li>
                <li>Assistance during the inspection to ensure you are not held accountable for any potential pre-existing damage</li>
                <li>Installation services including setting up utility contracts (electricity, gas, water)</li>
                <li>Setting up the housing insurance contract</li>
                <li>Interfacing with the landlord/property management agency for all property related issues encountered during the entry inspection</li>
            </ul>

            <h3>Gold Settling-In Services</h3>
            <ul>
                <li>Setting up internet and cell-phone subscriptions</li>
                <li>Setting up any other contracts not included in previous packages but that are mandatory as per rental obligations (maintenance contract for individual gas heater, gardener etc.)</li>
                <li>Support up to one-month past entry with all questions related to your property</li>
            </ul>


            <h3>Platinum Settling-In Services</h3>
            <ul>
                <li>Setting up internet and cell-phone subscriptions</li>
                <li>Setting up any other contracts not included in previous packages but that are mandatory as per rental obligations (maintenance contract for individual gas heater, gardener etc.)</li>
                <li>Interfacing with the landlord/property management agency for all property related issues encountered during the entry inspection</li>
            </ul>









                </Col>
            </Row>

            
        </Container>            
        </>
    );
}

export default Content;