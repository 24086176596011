import { useEffect} from 'react';

// import { LoremIpsum } from "react-lorem-ipsum";


// Import Sections
import IntroText from './IntroText';
import IconGrid from './IconGrid';

const Home = () => {

 useEffect(() => {
     document.title = "Move To France";
   }, []);

  return (
    <>
        <IntroText />    
        <IconGrid />
    </>
  );
}

export default Home;