import { useEffect} from 'react';
// import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import logo from '../Assets/Images/move_to_france.svg';
import { Image, Container } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';

// import { LoremIpsum } from "react-lorem-ipsum";

import './Login.css';

const Content = () => {

 useEffect(() => {
     document.title = "Login";
   }, []);

  return (
    <>
    <Container className="" fluid >
        <div className="form-signin w-100 m-auto">
            <form>
                    {/* <Image className="mb-4" src={ logo } width="72" height="57" ></Image> */}
                    <h1 className="h3 mb-3 fw-normal">Please sign in</h1>

                    <div className="form-floating">
                        <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com" />
                        <label for="floatingInput">Email address</label>
                    </div>
                    
                    <div className="form-floating">
                        <input type="password" className="form-control" id="floatingPassword" placeholder="Password" ></input>
                        <label for="floatingPassword">Password</label>
                    </div>

                    <Button className="w-100 py-2" href="/profile">Sign in</Button>
                    <p className="mt-5 mb-3 text-body-secondary">© 2024</p>
            </form>
        </div>
    </Container>
    </>

);
}

export default Content;