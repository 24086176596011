import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap/dist/react-bootstrap.js";
import { Routes, Route } from 'react-router-dom';

import './App.css';
import './Theme.css';

import "@fortawesome/fontawesome-free/css/all.css";



import Home from './Pages/Home/Home';
import Considerations from './Pages/Considerations/Considerations';
import HomeFinding from './Pages/Services/HomeFinding';
import Relocation from './Pages/Services/Relocation';
import Settling from './Pages/Services/Settling';
import Discovery from './Pages/Calendar';


import Contact from './Pages/ContactForm/Contact';

import Login from './Pages/Login';
import Profile from './Pages/Profile';

import NavBar from './Components/NavBar';
import NoMatch from './Components/NoMatch';
import Footer from './Components/Footer';



const App = () => {
  return (
     <>
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/considerations" element={<Considerations />} />
        <Route path="/home-finding-services" element={<HomeFinding />} />
        <Route path="/relocation-services" element={<Relocation />} />
        <Route path="/settling-services" element={<Settling />} />

        <Route path="/book-discover-call" element={<Discovery />} />


        <Route path="/login" element={<Login />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/contact" element={<Contact />} />

        <Route path="*" element={<NoMatch />} />

      </Routes>
      <Footer />
 
     </>
  );
 };
 
 
export default App;
 

