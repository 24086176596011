import { useEffect} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import home_finding_title from '../../Assets/Images/moving_to_france_home_8.jpg';

const Content = () => {
    useEffect(() => {
        document.title = "Relocation Services";
    }, []);
   
    return (
        <>
        <Container className='bg-white  g-0'>
            <div class="p-4 p-md-5 rounded text-body-emphasis background-blend " style={{ backgroundImage: `url("${home_finding_title}")` }}>
                <div class="col-lg-6 px-3 text-blend">
                    <h1 class=" ">Relocation Services</h1>
                    <p class="lead my-3">Relocating to France opens the door to a rich tapestry of culture, breathtaking landscapes, and a vibrant lifestyle. From the charming villages to bustling cities, this journey promises new experiences, delicious cuisine, and a welcoming community ready to embrace you.</p>
                    <p class="lead mb-0"><a href="/contact" class="text-body-emphasis fw-bold">Contact Us</a></p>
                </div>
            </div>
        </Container>
            
        <Container className=" bg-white "  >
            <Row>
                
                <Col xs={12} md={12} className="d-flex flex-column   p-3 ">
                    <p>Relocating to France is an exciting adventure that opens the door to a rich tapestry of culture, history, and stunning landscapes. From the vibrant streets of Paris to the serene countryside of Provence, each region offers its own unique charm and lifestyle. The process begins with essential preparations, such as understanding visa requirements, securing housing, and familiarizing yourself with local customs. Embracing the French language can also enhance your experience and ease daily interactions, making it a valuable skill for both social and professional integration.</p>
                    <p>Once settled, you’ll find that life in France is characterized by a strong appreciation for art, cuisine, and community. The French take pride in their culinary traditions, from fresh markets to exquisite dining, offering an unparalleled gastronomic experience. Engaging in local activities, exploring historical sites, and participating in festivals will help you forge connections and feel at home in your new environment. With its welcoming atmosphere and diverse opportunities, relocating to France can be a fulfilling journey toward a new chapter in your life.</p>
                        
                        <h2>Why Choose Our Relocation Service?</h2>
                        <p>Choosing our relocation services ensures a seamless transition to your new life in France, backed by our expertise and personalized support. We understand that moving to a new country can be daunting, which is why our team is dedicated to guiding you through every step of the process—from visa applications and housing searches to cultural orientation and language resources. With our extensive local knowledge and strong network of contacts, we can simplify logistics, save you time, and alleviate the stress of relocation. Trust us to provide the tailored assistance you need to make your move as smooth and enjoyable as possible.</p>
                        
                </Col>
            </Row>

            <Row>
            <Col xs={12} md={12} className="d-flex flex-column   ">
                <h2 class="fw-normal">Silver</h2>
                <p>Our Silver relocation service is a cost-efficient solution for those who are having trouble finding and negotiating a property on their own and prefer having the assistance of a professional relocation agency to facilitate this process. 
                This package is the perfect solution for young professionals who have a tighter budget, for Francophones who can handle the various installation services on their own, and for those who have more time to manage the settling-in side of their relocation.</p>
                <p><a class="btn btn-outline-primary " href="/contact">Contact Us</a></p>
            </Col>
            <Col xs={12} md={12} className="d-flex flex-column  ">
                <h2 class="fw-normal">Gold</h2>
                <p>Our Gold relocation service is perfect to help you find long-term housing in France and settle into your new lives with ease.</p>
                <p><a class="btn btn-outline-primary " href="/contact">Contact Us</a></p>
            </Col>
            <Col xs={12} md={12} className="d-flex flex-column   ">
                <h2 class="fw-normal">Platinum</h2>
                <p>Our Platinum service is tailored to those who want a VIP relocation experience. A consultant will be fully dedicated to you and will take the time to ensure all your needs are addressed. This package will provide the most seamless relocation experience.
This package covers all your relocation needs to find long-term luxury housing in France, get settled into your new home, and offers additional services to ensure all the needs of your entire family are taken care of.
Airport pickup with a private chauffeur for family and luggage
Opening a French bank account
Half-day tour to get better acquainted with the city and its different neighborhoods
Orientation visit with your dedicated consultant to get your bearings once settled
Intercultural training for your whole family
Support up to three months past entry with all questions related to your property.</p>
                <p><a class="btn btn-outline-primary " href="/contact">Contact Us</a></p>
            </Col>
    </Row>
        </Container>            
        </>
    );
}

export default Content;