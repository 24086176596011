import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Container } from 'react-bootstrap';

function IconGrid() {
    return (
        <>
    <Container className=" bg-white">
        
    <h2 class="pb-2 border-bottom">Our Services</h2>

    <Row className="row g-4 py-5">


      <Col className="col-md-6 col-lg-4 mb-3 d-flex gap-3">
        <i class="bi fs-5 flex-shrink-0 mt-1 fa-solid fa-graduation-cap" />
        <div>
          <h3 className="fw-bold mb-0 fs-4  third-color">Education</h3>
          <p>We can find you top-rated schools in France, offering excellent educational opportunities for your children.</p>
        </div>
      </Col>   
      
      <Col className="col-md-6 col-lg-4 mb-3 d-flex gap-3">
        <i class="bi fs-5 flex-shrink-0 mt-1 fa-solid fa-house-chimney-window" />
        <div>
          <h3 className="fw-bold mb-0 fs-4  third-color">Housing</h3>
          <p>Discover your perfect home in France with our expert guidance and extensive property listings.</p>
        </div>
      </Col>   

      <Col className="col-md-6 col-lg-4 mb-3 d-flex gap-3">
        <i class="bi fs-5 flex-shrink-0 mt-1 fa-solid fa-truck-moving" />
        <div>
          <h3 className="fw-bold mb-0 fs-4  third-color">Moving</h3>
          <p>Experience hassle-free international relocation from comprehensive moving services, tailored to meet your needs.</p>
        </div>
      </Col>   

      <Col className="col-md-6 col-lg-4 mb-3 d-flex gap-3">
        <i class="bi fs-5 flex-shrink-0 mt-1 fa-solid fa-coins" />
        <div>
          <h3 className="fw-bold mb-0 fs-4  third-color">Finance</h3>
          <p>Let us help setup a banking service tailored for you. French banks are known for their robust regulatory frameworks.</p>
        </div>
      </Col>   

      <Col className="col-md-6 col-lg-4 mb-3 d-flex gap-3">
        <i class="bi fs-5 flex-shrink-0 mt-1 fa-solid fa-person-walking" />
        <div>
          <h3 className="fw-bold mb-0 fs-4  third-color">Lifestyle</h3>
          <p>Life in France is celebrated for its blend of rich culture, exquisite cuisine, and a leisurely pace that values art, family, and social gatherings.</p>
        </div>
      </Col>   

      <Col className="col-md-6 col-lg-4 mb-3 d-flex gap-3">
        <i class="bi fs-5 flex-shrink-0 mt-1 fa-solid fa-briefcase" />
        <div>
          <h3 className="fw-bold mb-0 fs-4  third-color">Working</h3>
          <p>We can take the burden out of finding work in France, which often requires navigating a competitive job market and understanding the importance of networking and language skills.</p>
        </div>
      </Col>   

      <Col className="col-md-6 col-lg-4 mb-3 d-flex gap-3">
        <i class="bi fs-5 flex-shrink-0 mt-1 fa-regular fa-hospital" />
        <div>
          <h3 className="fw-bold mb-0 fs-4  third-color">Healthcare</h3>
          <p>France boasts a highly regarded healthcare system known for its accessibility, quality of care, and universal coverage through a mix of public and private funding.</p>
        </div>
      </Col>   

      <Col className="col-md-6 col-lg-4 mb-3 d-flex gap-3">
          <i class="bi fs-5 flex-shrink-0 mt-1 fa-solid fa-basket-shopping" />
        <div>
          <h3 className="fw-bold mb-0 fs-4  third-color">Living</h3>
          <p>Living in France offers a vibrant blend of history, culture, and scenic beauty, enriching everyday experiences with a strong emphasis on art, food, and community..</p>
        </div>
      </Col>      

      <Col className="col-md-6 col-lg-4 mb-3 d-flex gap-3">
          <i class="bi fs-5 flex-shrink-0 mt-1 fa-solid fa-address-book" />
        <div>
          <h3 className="fw-bold mb-0 fs-4  third-color">Business Directory</h3>
          <p>Our business directory stands out with its comprehensive listings, and tailored search features, making it the ultimate resource for finding and connecting with local businesses.</p>
        </div>
      </Col>       
      
    </Row>
    </Container>
        </>
    );
}

export default IconGrid;