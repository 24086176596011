import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import header_image from '../../Assets/Images/moving_to_france_home_5.jpg';


const IntroText = () => {
    return (
        <>
    <Container className='g-0 bg-white rounded'>
        <div class="p-4 p-md-5 rounded text-body-emphasis background-blend " style={{ backgroundImage: `url("${header_image}")` }}>
            <div class="col-lg-4 px-3 text-blend">
                <h1 class=" ">Welcome to France,</h1>
                <p class="lead my-3"> Your essential guide for a smooth transition from the UK to the heart of France! Whether you’re seeking information on visas, housing, or cultural integration, we provide tailored resources to help you navigate the journey. Embrace the French lifestyle with confidence and discover everything you need for a successful move! </p>
                <p class="lead mb-0"><a href="/contact" class="text-body-emphasis fw-bold">Contact Us</a></p>
            </div>
        </div>
    </Container>

        <section >
            <Container className='bg-white ' >
                <Row >
                    <Col xs={12} md={12} className="">
                    <h1>Welcome to Move to France!</h1>
<p>Are you dreaming of relocating to the picturesque landscapes, rich culture, and vibrant lifestyle of France? You're in the right place! At Your Move to France, we specialize in helping UK residents transition smoothly to their new homes across the Channel. Whether you're moving for work, retirement, or simply a change of scenery, our comprehensive services cover every aspect of your move. From handling logistics and paperwork to providing insights on local customs and lifestyle, we're here to make your journey as seamless and enjoyable as possible. Let us take care of the details, so you can focus on embracing your exciting new adventure in France. Welcome aboard!</p>
                        <p>Welcome to <strong>Move To France</strong>, your premier partner for seamless relocation services in France. With a rich cultural tapestry, vibrant cities, and picturesque countryside, France beckons individuals and families from around the world to experience its unique charm and opportunities. Whether you're an expatriate, a corporate entity, or a global nomad seeking to make France your new home, navigating the complexities of relocation can be daunting. That's where we step in.</p>
                        {/* <p>At <strong>Move To France</strong>, we specialize in providing comprehensive relocation solutions tailored to meet your specific needs and ensure a smooth transition to life in France. Our team of experienced professionals understands the intricacies of the relocation process, from visa acquisition to settling into your new home and everything in between.</p>
                        <p>From bustling metropolises like Paris and Lyon to idyllic rural towns nestled in the French countryside, we offer personalized relocation services across the length and breadth of France. Whether you require assistance with housing search and selection, school enrollment for your children, or navigating the French bureaucratic maze, we are here to alleviate the stress and uncertainty associated with relocation.</p>
                        <p>We pride ourselves on delivering unparalleled customer service, attention to detail, and a deep understanding of the unique challenges faced by individuals and families relocating to France. With our extensive network of local partners and service providers, we ensure that every aspect of your relocation journey is handled with professionalism, efficiency, and care.</p> */}
                        {/* <p>Here is a list of services we can offer</p> */}

                        {/* Pre-arrival Assistance:
                        <ul>
                            <li>Visa and immigration assistance: Assistance with visa requirements, application procedures, and necessary documentation for obtaining various types of visas, such as tourist, student, work, or residency permits.</li>
                            <li>Housing search: We can help find suitable accommodation based on their preferences, budget, and location requirements. This includes rental apartments, houses, or temporary lodging options.</li>
                            <li>Neighborhood orientation: Our team can offer insights into different neighborhoods , amenities, schools, transportation options, and cultural aspects to help clients choose the best area to live in.</li>
                        </ul>
                        
                        Settling-in Services:
                        Administrative support: We can assist with administrative tasks such as opening a bank account, obtaining a tax identification number (NIF/NIE), setting up utilities (electricity, water, internet), and registering with local authorities.
                        School enrollment: For families with children, consultants facilitate the enrollment process in local schools or international schools, providing information on curriculum, admission requirements, and enrollment deadlines.
                        Health care guidance: We offer guidance on accessing the French healthcare system, registering with a local doctor, obtaining health insurance, and understanding healthcare coverage options.
                        Cultural and Language Support:
                        Cultural orientation: Our team can provide cultural orientation sessions to help clients understand French customs, etiquette, and social norms, easing the transition to the new cultural environment.
                        Spousal and Family Support:
                        Job search assistance: For accompanying spouses or partners seeking employment opportunities, we can provide assistance, networking opportunities, and guidance on navigating the French job market.
                        Family integration:We can also offer support for spouses and children, including recommendations for social activities, clubs, childcare services, and community events to facilitate integration and networking.


                                Departure Services:
                                Departure assistance: When it's time to leave France, we can help with lease terminations, utility closures, and other departure formalities.
Repatriation support: We can offer guidance on shipping belongings, closing bank accounts, terminating contracts, and ensuring a smooth transition back to your home country. */}
                    </Col>
                </Row>
            </Container>
        </section>
        </>
    );
}

export default IntroText;